import dynamic from 'next/dynamic';

import { Container } from '@/atoms';
import { ProductBlock } from '@/organisms';
import { CardProductWordpressServer } from '@/templates';

import { cardBannerTransformer } from '@/lib/graphql/transformers';
import { isNumber, removeBaseURL } from '@/lib/utils';

const CardBanner = dynamic(() => import('@/molecules/card-banner'));
const CardBlog = dynamic(() => import('@/molecules/card-blog'));
const Grid = dynamic(() => import('@/molecules/grid'));
const GridItem = dynamic(() =>
  import('@/molecules/grid/GridItem').then((mod) => mod.GridItem),
);
const SliderProduct = dynamic(() => import('@/molecules/slider-product'));

const ProductosFlexible = ({ bloque, id, productos, className }: any) => {
  const items = bloque.mostrarProductosRelacionados
    ? productos
    : bloque.productos;

  return (
    <Container
      id={id}
      wrapper
      {...(className && { className })}
      as="section"
      {...(bloque.backgroundColorString
        ? {
            style: { backgroundColor: bloque.backgroundColorString },
            padding: 'px-4 py-8 md:py-8',
          }
        : {})}
    >
      <ProductBlock
        {...(bloque.titulo ? { title: bloque.titulo } : {})}
        {...(bloque.link?.link
          ? {
              link: {
                href: removeBaseURL(bloque.link.link.url) || '/',
                label: bloque.link.label,
                size: bloque.link.size,
                style: bloque.link.style,
              },
            }
          : {})}
        {...(bloque.description ? { description: bloque.description } : {})}
        {...(bloque.mostrarProductosRelacionados
          ? { title: 'Productos Relacionados' }
          : {})}
        productos={items}
        itemListName={bloque.itemListName}
        headingType={bloque.headingType}
      >
        {bloque.isGrid ? (
          <Grid {...(Number(bloque.itemsPorPagina) === 4 && { lg: 4 })}>
            {items.map((item: any, index: number) => (
              <GridItem key={index}>
                {item.postType === 'banner' ? (
                  <CardBanner
                    {...cardBannerTransformer(item.cpt_banner)}
                    key={index}
                  />
                ) : item.postType === 'blog' ? (
                  <CardBlog {...item} key={index} />
                ) : (
                  <CardProductWordpressServer
                    product={item}
                    itemListName={bloque.itemListName}
                    itemIndex={index + 1}
                    key={index}
                    numColumns={
                      isNumber(bloque.itemsPorPagina)
                        ? Number(bloque.itemsPorPagina)
                        : 6
                    }
                  />
                )}
              </GridItem>
            ))}
          </Grid>
        ) : (
          <SliderProduct
            perPage={
              isNumber(bloque.itemsPorPagina)
                ? Number(bloque.itemsPorPagina)
                : 6
            }
            {...(Number(bloque.itemsPorPagina) === 4
              ? {
                  breakpoints: {
                    1280: {
                      perPage: 4,
                    },
                    1024: {
                      perPage: 3,
                    },
                    768: {
                      padding: 40,
                      perPage: 2,
                    },
                    640: {
                      padding: 40,
                      perPage: 2,
                    },
                    450: {
                      padding: 40,
                      perPage: 1,
                    },
                  },
                }
              : {})}
            className="-mx-4 md:mx-0"
          >
            {items.map((item: any, index: number) =>
              item.postType === 'banner' ? (
                <CardBanner
                  {...cardBannerTransformer(item.cpt_banner)}
                  key={index}
                />
              ) : item.postType === 'blog' ? (
                <CardBlog {...item} key={index} />
              ) : (
                <CardProductWordpressServer
                  product={item}
                  itemListName={bloque.itemListName}
                  itemIndex={index + 1}
                  key={index}
                  numColumns={
                    isNumber(bloque.itemsPorPagina)
                      ? Number(bloque.itemsPorPagina)
                      : 6
                  }
                />
              ),
            )}
          </SliderProduct>
        )}
      </ProductBlock>
    </Container>
  );
};

export default ProductosFlexible;

ProductosFlexible.displayName = 'FlexibleProductos';
